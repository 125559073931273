.container {
    width: 100%;

    svg {
        width: 100%;
        height: auto;
    }

    .warningIcon,
    .hookAndBubble,
    .bubble {
        transform-box: fill-box;
        transform-origin: center;
    }

    .warningIcon {
        animation: flash 1.5s ease-in-out 0s infinite forwards;
    }

    .hookAndBubble {
        animation: fishing 5s ease-in-out 0s infinite forwards;

        .bubble {
            transform-origin: 40% 50%;
            animation: sway 5s ease-in-out 1s infinite forwards;
        }
    }
}

@keyframes flash {
    0% {
        transform: scale(1) rotate(0deg);
    }

    85% {
        transform: scale(1) rotate(0deg);
    }

    89% {
        transform: scale(1) rotate(-7deg);
    }

    90% {
        transform: scale(1.1) rotate(7deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}

@keyframes sway {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes fishing {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(9%);
    }

    100% {
        transform: translateY(0%);
    }
}
